import { useState, useEffect, useCallback } from "react";
import axios from "axios";

const useMySubscriptions = () => {
  const [subscriptions, setSubscriptions] = useState([]);
  const [dailyAgentRunLimit, setDailyAgentRunLimit] = useState(0);
  const [totalDailyRunAllocation, setTotalDailyRunAllocation] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchSubscriptions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await axios.get(`api/attache_agents/my_subscriptions`);
      setSubscriptions(response.data.subscriptions);
      setDailyAgentRunLimit(response.data.daily_agent_run_limit);
      setTotalDailyRunAllocation(response.data.total_daily_run_allocation);
      setError(null);
    } catch (err) {
      setError(err.message || "Failed to fetch subscriptions");
    } finally {
      setLoading(false);
    }
  }, []);

  const toggleSubscriptionActiveState = async (subscriptionId, newDailyRunAllocation) => {
    try {
      // Force newDailyRunAllocation to 0 if it's not defined
      console.log(subscriptionId, newDailyRunAllocation)
      
      await axios.put(`api/attache_agents/my_subscriptions/${subscriptionId}/${newDailyRunAllocation}`);
      fetchSubscriptions();
    } catch (err) {
      setError(err.message || "Failed to toggle the subscription state");
    }
  };

  const deleteSubscription = async (subscriptionId) => {
    try {
      await axios.delete(
        `api/attache_agents/my_subscriptions/${subscriptionId}`
      );
      fetchSubscriptions(); // Refresh the list after deletion
    } catch (err) {
      setError(err.message || "Failed to delete subscription");
    }
  };

  useEffect(() => {
    fetchSubscriptions();
  }, [fetchSubscriptions]);

  return {
    subscriptions,
    setSubscriptions,
    dailyAgentRunLimit,
    totalDailyRunAllocation,
    loading,
    error,
    toggleSubscriptionActiveState,
    deleteSubscription,
  };
};

export default useMySubscriptions;
