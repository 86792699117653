import React from 'react';
import { Card, Text, Group, Button, NumberInput, Stack } from '@mantine/core';
import { IconChevronUp, IconChevronDown, IconPlayerPlay, IconPlayerPause, IconFlag } from '@tabler/icons-react';

const AllocationControl = ({
  isStarted,
  dailyAgentRunLimit,
  totalDailyRunAllocation,
  dailyRunAllocation,
  newDailyRunAllocation,
  setNewDailyRunAllocation,
  handleStart,
  isStartBlocked
}) => {
  const isAllocationChanged = newDailyRunAllocation !== dailyRunAllocation;
  const availableAllocation = dailyAgentRunLimit - totalDailyRunAllocation + dailyRunAllocation - newDailyRunAllocation;

  const getActionButton = () => {
    if (!isStarted && newDailyRunAllocation > 0) {
      return (
        <Button
          color="green"
          onClick={handleStart}
          disabled={isStartBlocked}
          fullWidth
          leftIcon={<IconFlag size={14} />}
        >
          Start Competition
        </Button>
      );
    } else if (isStarted && isAllocationChanged) {
      return (
        <Button
          color={"blue"}
          onClick={handleStart}
          disabled={!isAllocationChanged}
          fullWidth
          leftIcon={<IconPlayerPlay size={14} />}
        >
          Update Allocation
        </Button>
      );
    }
    return null;
  };

  return (
    <Card shadow="sm" p="lg" radius="md" withBorder>
      <Stack>
        <Text size="sm" weight={500}>Set Daily Run Allocation</Text>
        <Group position="apart" align="center">
          <NumberInput
            value={newDailyRunAllocation}
            onChange={(value) => setNewDailyRunAllocation(value)}
            min={0}
            max={dailyAgentRunLimit - totalDailyRunAllocation + dailyRunAllocation}
            step={1}
            styles={{ input: { width: 60, textAlign: 'center' } }}
            rightSection={
              <Stack spacing={0}>
                <Button
                  compact
                  variant="subtle"
                  size="xs"
                  onClick={() => setNewDailyRunAllocation(Math.min(newDailyRunAllocation + 1, dailyAgentRunLimit - totalDailyRunAllocation + dailyRunAllocation))}
                  disabled={newDailyRunAllocation >= dailyAgentRunLimit - totalDailyRunAllocation + dailyRunAllocation}
                >
                  <IconChevronUp size={14} />
                </Button>
                <Button
                  compact
                  variant="subtle"
                  size="xs"
                  onClick={() => setNewDailyRunAllocation(Math.max(newDailyRunAllocation - 1, 0))}
                  disabled={newDailyRunAllocation <= 0}
                >
                  <IconChevronDown size={14} />
                </Button>
              </Stack>
            }
            rightSectionWidth={30}
          />
        <Stack spacing={0} align="flex-end">
          {dailyRunAllocation !== newDailyRunAllocation && (
            <Text size="xs" color="dimmed">Previous: {dailyRunAllocation}</Text>
          )}
          <Text size="xs" color="dimmed">Available: {availableAllocation}</Text>
        </Stack>
        </Group>
        {getActionButton()}
      </Stack>
    </Card>
  );
};

export default AllocationControl;