import React, { useState } from 'react';
import {
  Card,
  Text,
  Badge,
  Button,
  Group,
  Stack,
  Box,
  ActionIcon,
} from '@mantine/core';
import { IconChevronUp, IconChevronDown, IconPlayerPlay, IconPlayerPause, IconFlag } from '@tabler/icons-react';

const AllocationControlMySubscriptions = ({
  subscription,
  dailyAgentRunLimit,
  totalDailyRunAllocation,
  onUpdate,
  isStartBlocked
}) => {
  const [newAllocation, setNewAllocation] = useState(subscription.daily_run_allocation);
  const isStarted = subscription.is_started;
  const isAllocationChanged = newAllocation !== subscription.daily_run_allocation;
  const availableAllocation = dailyAgentRunLimit - totalDailyRunAllocation + subscription.daily_run_allocation;

  const handleConfirmUpdate = () => {
    onUpdate(subscription.id, newAllocation);
  };

  const getActionButton = () => {
    if (!isStarted && newAllocation > 0) {
      return (
        <Button
          color="green"
          onClick={handleConfirmUpdate}
          disabled={isStartBlocked || !isAllocationChanged}
          fullWidth
          size="lg"
          leftIcon={<IconFlag size={20} />}
        >
          Start
        </Button>
      );
    } else if (isStarted && isAllocationChanged) {
      return (
        <Button
          color="blue"
          onClick={handleConfirmUpdate}
          disabled={!isAllocationChanged}
          fullWidth
          size="lg"
          leftIcon={isAllocationChanged ? <IconPlayerPlay size={20} /> : <IconPlayerPause size={20} />}
        >
          Update Allocation
        </Button>
      );
    }
    return null;
  };

  return (
    <Card shadow="sm" p="xl" radius="md" withBorder>
      <Stack spacing="md">
        <Text size="lg" weight={700}>Set Daily Run Allocation</Text>
        <Group position="apart" align="center">
          <Group spacing={10}>
            <ActionIcon
              size="xl"
              variant="light"
              onClick={() => setNewAllocation(Math.max(newAllocation - 1, 0))}
              disabled={newAllocation <= 0}
            >
              <IconChevronDown size={24} />
            </ActionIcon>
            <Box 
              sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[0],
                padding: theme.spacing.md,
                borderRadius: theme.radius.md,
                minWidth: '60px',
                textAlign: 'center',
              })}
            >
              <Text size="xl" weight={700}>{newAllocation}</Text>
            </Box>
            <ActionIcon
              size="xl"
              variant="light"
              onClick={() => setNewAllocation(Math.min(newAllocation + 1, availableAllocation))}
              disabled={newAllocation >= availableAllocation}
            >
              <IconChevronUp size={24} />
            </ActionIcon>
          </Group>
          <Stack spacing={5} align="flex-end">
            {subscription.daily_run_allocation !== newAllocation && (
              <Text size="sm" color="dimmed">Previous: {subscription.daily_run_allocation}</Text>
            )}
            <Text size="sm" color="dimmed">Available: {availableAllocation - newAllocation}</Text>
          </Stack>
        </Group>
        <Box mt="md">
          {getActionButton()}
        </Box>
      </Stack>
    </Card>
  );
};

export default AllocationControlMySubscriptions;